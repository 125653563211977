/* istanbul ignore file */
import React, { useContext, useState, useEffect } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";

import { useSearchParams } from "../../../hooks/useSearchParams";

import SearchPage from "./SearchPage";
import { RESULTS_ALL_INDEX } from "./SearchRelevanceSelector";
import FilterTab from "./FilterTab/FilterTab";

import { useApi } from "../../../hooks/useApi";

// import { base as ldbase } from "../../../utils/LDFFUtils";
import ConfigContext from "../../../context/ConfigContext";
import { getLocalStorageLoseSelectionOK } from "../../../utils/LocalStorageUtils";
import { partitionByRelevancy } from "../../../utils/FileUtils";
import { toInt } from "../../../utils/StringUtils";
import { useFileAndPageContext } from "../../../context/FileAndPageContext";

import {
  SELECT_ALL_FLAG,
  CLEAR_ALL_FLAG,
} from "../../Downloader/SelectAllButton/SelectAllButton";

import {
  CONFIRM_APPLY_FILTER,
  CONFIRM_APPLY_RELEVANCY,
} from "../../UI/InfoBoard/ConfirmChange";

import * as cartTool from "../../../utils/CartDataTools";

const DEFAULT_PAGE_SIZE = 10;

/**
  delegate different views to view-specific components
 */
const SearchPageContainer = (props) => {
  // for filters
  const { organismFilters, fileFilters, goldFilters } =
    useContext(ConfigContext);
  const { cartData, addFilesToCart: _addFilesToCart } = useFileAndPageContext();
  const { searchParams } = useSearchParams();
  const { honeycomb, ldClient } = props;
  const { restoreid, cart } = useSearchParams();
  const [newSearchString, setNewSearchString] = useState("");
  const [selection, setSelection] = useState(null);
  const [confirmApplyType, setConfirmApplyType] = useState("");
  const [storageOverflow, setStorageOverflow] = useState(null);
  const [mostRelevantSelectorIndex, setMostRelevantSelectorIndex_] =
    useState(0);

  const initPageNumber = toInt(searchParams.get("p"), 1); // default can from config

  const pageSize = toInt(searchParams.get("x"), DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(initPageNumber);
  const [pageMax, setPageMax] = useState(1);
  const [filterGroups, setFilterGroups] = useState(null);

  const history = useHistory();
  const { useGenomeQuery, searchCounts, resetSearchCounts } = useApi(honeycomb);

  const { data, isLoading, isFetchingNextPage } = useGenomeQuery(ldClient);

  useEffect(() => {
    const filterGroupList = filterGroups
      ? JSON.parse(JSON.stringify(filterGroups))
      : [
          {
            label: "Environment",
          },
          { label: "Dataset" },
          { label: "File Property" },
        ];
    [goldFilters, organismFilters, fileFilters].forEach((config, idx) => {
      filterGroupList[idx].config = config;
      filterGroupList[idx].index = idx;
    });

    setFilterGroups(filterGroupList);
  }, [goldFilters, organismFilters, fileFilters]);

  const addFilesToCart = (files) => {
    // need to clone the existing cartData, preserve it's original data in case failed to save to localStorage

    const info = _addFilesToCart(files);
    if (info?.error) {
      setStorageOverflow(info);
    }
  };

  const computePageMax = (relSelectorIndex) => {
    // helper to re-evaluate page max for the given relevancy selection, data must exists!
    /* istanbul ignore next */
    const pMax = data
      ? relSelectorIndex === 1
        ? Math.ceil(data.total / pageSize)
        : Math.ceil(data.prioritized_total / pageSize)
      : 1;
    return pMax > 0 ? pMax : 1;
  };

  const setSelectedFiles = (files, forCart = false, allFlag = null) => {
    if ([CLEAR_ALL_FLAG, SELECT_ALL_FLAG].includes(allFlag)) {
      if (allFlag === CLEAR_ALL_FLAG) {
        if (selection?.length > 0) {
          honeycomb.sendUiInteractionSpan("deselect-all-clicked");
          setSelection(null);
        }
      } else if (data?.organisms) {
        honeycomb.sendUiInteractionSpan("select-all-clicked");
        const sMin = data.relevant_min_score;
        const allFiles = [];
        data.organisms.forEach((org) => {
          if (
            mostRelevantSelectorIndex === RESULTS_ALL_INDEX ||
            org.score.avg >= sMin
          ) {
            const cartFileIds =
              cartData?.organisms?.length > 0
                ? cartTool.fileIdsForOrgId(cartData, org.id)
                : [];
            org.files.forEach((file) => {
              if (cartFileIds.length === 0 || !cartFileIds.includes(file._id)) {
                allFiles.push(file);
              }
            });
          }
        });
        setSelection(allFiles);
      }
    } else if (forCart) {
      // called by AddToCart - update cart
      addFilesToCart(files);
      setSelection(null);
    } else {
      setSelection(files);
    }
  };

  const setMostRelevantSelectorIndex = (idx, force = false) => {
    // hijack the hook to only update pageSize/pageNumber, so no change for user selections
    if (!data) return;

    const doNotAsk = getLocalStorageLoseSelectionOK();
    const partitions = partitionByRelevancy(selection, data.relevant_min_score);
    /* istanbul ignore next */
    const notify =
      !doNotAsk &&
      !force &&
      mostRelevantSelectorIndex === RESULTS_ALL_INDEX &&
      idx === 0 &&
      partitions.under.length > 0
        ? CONFIRM_APPLY_RELEVANCY
        : "";

    if (notify) {
      // From Show All to Show Most Relevant ...
      /* istanbul ignore next */
      setConfirmApplyType(notify);
    } else {
      const newPageMax = computePageMax(idx);

      /* istanbul ignore next */
      if (newPageMax !== pageMax) setPageMax(newPageMax);
      /* istanbul ignore next */
      if (pageNumber > newPageMax) setPageNumber(newPageMax);

      setMostRelevantSelectorIndex_(idx);

      // remove less relavent selections
      setSelectedFiles(partitions.over);
    }
  };

  let filterConfig = organismFilters
    ? organismFilters.concat(fileFilters)
    : fileFilters;
  if (filterConfig && goldFilters) {
    filterConfig = filterConfig.concat(goldFilters);
  }

  const handleApplyFilter = (sString = "", force = false) => {
    // reset selection to none; reset page to 1
    setNewSearchString(sString);

    const doNotAsk = getLocalStorageLoseSelectionOK();

    /* istanbul ignore next */
    const notify =
      selection?.length > 0 && !force && !doNotAsk ? CONFIRM_APPLY_FILTER : "";

    setConfirmApplyType(notify);
    if (sString && !notify) {
      setSelectedFiles([]);

      const params = new URLSearchParams(sString); // sString with the intented filters
      params.delete("p");
      history.push(`?${params.toString()}`);
    }
  };

  const searchPageProps = {
    data,
    isLoading,
    searchCounts,
    resetSearchCounts,
    isFetchingNextPage,
    filterGroups,
    newSearchString,
    handleApplyFilter,
    selection,
    setSelection,
    confirmApplyType,
    setConfirmApplyType,
    setSelectedFiles,
    addFilesToCart,
    storageOverflow,
    setStorageOverflow,
    mostRelevantSelectorIndex,
    setMostRelevantSelectorIndex,
    setMostRelevantSelectorIndex_,
  };
  if (!restoreid && !cart) {
    const filterColumnStyle = {
      width: "auto",
      border: "solid 1px #CCCCCC",
      marginRight: 10,
      backgroundColor: "#FFFFFF",
      borderRadius: 6,
    };

    return (
      <Box
        className="BodyBoxWithSideFilterBox"
        sx={{ display: "flex", height: "100%" }}
      >
        <Box
          className="SideFilterBox"
          id="filer-tab-container-id"
          style={filterColumnStyle}
        >
          {filterGroups && (
            <FilterTab
              data={data}
              id="paper-filter-bar"
              isLoading={data ? false : isLoading}
              applyFilter={handleApplyFilter}
              filterConfig={filterConfig}
              goldFilters={goldFilters}
              organismFilters={organismFilters}
              fileFilters={fileFilters}
              honeycomb={honeycomb}
              filterGroups={filterGroups}
              setFilterGroups={setFilterGroups}
            />
          )}
        </Box>
        <Box className="BodyBox" sx={{ width: "100%" }}>
          <SearchPage {...props} {...searchPageProps} />
        </Box>
      </Box>
    );
  }
  return (
    <Box className="BodyBox" sx={{ overflow: "auto", height: "auto" }}>
      <SearchPage {...props} {...searchPageProps} />
    </Box>
  );
};

export default SearchPageContainer;

SearchPageContainer.defaultProps = {
  ldClient: null,
  honeycomb: null,
};

SearchPageContainer.propTypes = {
  honeycomb: PropTypes.shape(),
  ldClient: PropTypes.shape(),
};
