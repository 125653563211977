import React, { useState, useEffect, useRef } from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";

import { useKeycloak } from "@react-keycloak/web";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Link } from "@material-ui/core";

import JDPAvatar from "../UI/Icons/Avatar/JDPAvatar";
import headerClasses from "./Header.module.css";

import cookieUtil from "../../utils/CookieHandler";
import { base as ldbase } from "../../utils/LDFFUtils";
import MimicUserDlg from "./Dialogs/MimicUserDlg";

import CopyNoticeTooltip from "../UI/Tooltip/TransientTooltip/TransientTooltip";

const LABEL_PMOS_STAGING = "Use PMOS Staging";
const LABEL_PMOS_PROD = "Use PMOS Prod";
const PopMenu = ({
  honeycomb,
  ldClientMaster,
  // ldClient,
  width,
  height,
  user,
  setDup,
  handleLogout,
  size,
}) => {
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [mimicUserDlg, setMimicUserDlg] = useState(false);
  const [pmosServer, setPMOSServer] = useState(LABEL_PMOS_STAGING); // menu item to switch to PMOS server

  const { keycloak } = useKeycloak();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const admin_ff = ldbase(ldClientMaster, "jdp-admins");

  const cktoken = cookieUtil.getKCSessionCookie();
  const ssotoken = cookieUtil.getSSOCookie();
  const code = `Bearer ${cktoken || ssotoken}`;

  useEffect(() => {
    const pmos = cookieUtil.getPMOSEnv();
    if (pmos === "staging") {
      setPMOSServer(LABEL_PMOS_PROD);
    }
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleLoginUserEdit = () => {
    honeycomb.sendUiInteractionSpan(`clicked-external-link: edit-jgi-user`);
    if (user.keycloak) {
      keycloak.accountManagement();
    } else {
      window.location.href = "https://signon.jgi.doe.gov/user/contacts/edit";
    }
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1500);
  };

  const handleMyDataPortal = () => {
    honeycomb.sendUiInteractionSpan("clicked-menu-my-data-portal");
    setOpen(false);
    history.push("/mydata");
  };

  const handleMimicUser = () => {
    setMimicUserDlg(true);
  };

  const handleSetPMOSSever = () => {
    const pmos = pmosServer === LABEL_PMOS_STAGING ? "staging" : null;
    cookieUtil.setPMOSEnv(pmos);
    setPMOSServer(
      pmosServer === LABEL_PMOS_STAGING ? LABEL_PMOS_PROD : LABEL_PMOS_STAGING
    );
    // reload page if current page is mydata
    if (window.location.pathname.indexOf("/mydata") === 0) {
      window.location.reload(false);
    }
  };

  const handleMimicUserCancel = () => {
    setMimicUserDlg(false);
  };

  const mainPanelStyle = {
    minWidth: `${width}px`,
    height: "auto",
    maxHeight: `${admin_ff ? 160 + height : height}px`,
    overflow: "auto",
    zIndex: 20,
    border: "1px solid #d9d9d9",
    marginRight: "1.5em",
    outline: "none",
  };

  const fullname = `${user.first_name} ${user.last_name}`;
  const uname = fullname
    .split(" ")
    .map((e) => e[0])
    .join("")
    .toUpperCase();

  const itemHeight = 40;

  const pulldownMenu = (
    <List>
      <ListItem divider>
        <ListItemIcon>
          {/* the avatar in dropdown manul */}
          <JDPAvatar label={fullname} color pointer={false} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography className={headerClasses.userMenuUserName}>
              {fullname}
            </Typography>
          }
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                className={headerClasses.userMenuUserEmail}
                style={{ color: "#444" }}
              >
                {user.email_address.toUpperCase()}
              </Typography>
            </>
          }
        />
      </ListItem>

      <ListItem button onClick={handleMyDataPortal} sx={{ height: itemHeight }}>
        <ListItemIcon>
          <ManageAccountsIcon sx={{ position: "relative", left: 4 }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={headerClasses.userMenuText}
              sx={{ padding: 0 }}
            >
              My Data Portal
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        onClick={handleLoginUserEdit}
        sx={{ height: itemHeight }}
      >
        <ListItemIcon> </ListItemIcon>
        <ListItemText
          primary={
            <Typography className={headerClasses.userMenuText}>
              Account info
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        data-testid="avatar-listitem-with-tool-tip"
        button
        onClick={() => {
          navigator.clipboard.writeText(code);
          handleTooltipOpen();
        }}
        sx={{ height: itemHeight }}
      >
        <ListItemIcon> </ListItemIcon>
        <CopyNoticeTooltip
          noticeLabel="Value copied to clipboard"
          child={
            <ListItemText
              primary={
                <Typography className={headerClasses.userMenuText}>
                  Copy My Session Token
                </Typography>
              }
            />
          }
          open={tooltipOpen}
        />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          setDup(new Date());
        }}
        sx={{ height: itemHeight }}
      >
        <ListItemIcon> </ListItemIcon>
        <ListItemText
          primary={
            <Typography className={headerClasses.userMenuText}>
              JGI Data Utilization Policy
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        onClick={handleLogout}
        sx={{ height: itemHeight }}
        divider={admin_ff}
      >
        <ListItemIcon> </ListItemIcon>
        <ListItemText
          primary={
            <Typography className={headerClasses.userMenuText}>
              Logout
            </Typography>
          }
        />
      </ListItem>

      {admin_ff && (
        <>
          <ListItem
            button
            onClick={handleMimicUser}
            sx={{ height: itemHeight }}
          >
            <ListItemIcon> </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  className={headerClasses.userMenuText}
                  style={{ fontStyle: "italic" }}
                >
                  Mimic User
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={handleSetPMOSSever}
            sx={{ height: itemHeight }}
          >
            <ListItemIcon> </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  className={headerClasses.userMenuText}
                  style={{ fontStyle: "italic" }}
                >
                  {pmosServer}
                </Typography>
              }
            />
          </ListItem>
          <ListItem button onClick={() => {}} sx={{ height: itemHeight }}>
            <ListItemIcon> </ListItemIcon>
            <Link
              component={RouterLink}
              target="_blank"
              to="//docs.jgi.doe.gov/pages/viewpage.action?pageId=17204307"
            >
              <ListItemText
                primary={
                  <Typography
                    className={headerClasses.userMenuText}
                    style={{ fontStyle: "italic" }}
                  >
                    Developer Doc
                  </Typography>
                }
              />
            </Link>
          </ListItem>
          <ListItem button onClick={() => {}} sx={{ height: itemHeight }}>
            <ListItemIcon> </ListItemIcon>
            <Link
              component={RouterLink}
              target="_blank"
              to="//app.launchdarkly.com/default/production/features/unexpected-issues/variations"
            >
              <ListItemText
                primary={
                  <Typography
                    className={headerClasses.userMenuText}
                    style={{ fontStyle: "italic" }}
                  >
                    Update outage message
                  </Typography>
                }
              />
            </Link>
          </ListItem>
          <ListItem button onClick={() => {}} sx={{ height: itemHeight }}>
            <ListItemIcon> </ListItemIcon>
            <Link
              component={RouterLink}
              target="_blank"
              to="//app.launchdarkly.com/default/production/features"
            >
              <ListItemText
                primary={
                  <Typography
                    className={headerClasses.userMenuText}
                    style={{ fontStyle: "italic" }}
                  >
                    Feature flags
                  </Typography>
                }
              />
            </Link>
          </ListItem>
          <ListItem button onClick={() => {}} sx={{ height: itemHeight }}>
            <ListItemIcon> </ListItemIcon>
            <Link
              component={RouterLink}
              target="_blank"
              to="//files.jgi.doe.gov/status/"
            >
              <ListItemText
                primary={
                  <Typography
                    className={headerClasses.userMenuText}
                    style={{ fontStyle: "italic" }}
                  >
                    Status endpoint
                  </Typography>
                }
              />
            </Link>
          </ListItem>
          <ListItem button onClick={() => {}} sx={{ height: itemHeight }}>
            <ListItemIcon> </ListItemIcon>
            <Link
              component={RouterLink}
              target="_blank"
              to="//files.jgi.doe.gov/admin/"
            >
              <ListItemText
                primary={
                  <Typography
                    className={headerClasses.userMenuText}
                    style={{ fontStyle: "italic" }}
                  >
                    JDP Database
                  </Typography>
                }
              />
            </Link>
          </ListItem>
          <ListItem button onClick={() => {}} sx={{ height: itemHeight }}>
            <ListItemIcon> </ListItemIcon>
            <Link
              component={RouterLink}
              target="_blank"
              to="//ui.honeycomb.io/joint-genome-institute/datasets/jgidp-production/home/"
            >
              <ListItemText
                primary={
                  <Typography
                    className={headerClasses.userMenuText}
                    style={{ fontStyle: "italic" }}
                  >
                    Honeycomb
                  </Typography>
                }
              />
            </Link>
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <>
      {/* the avatar in header */}
      <JDPAvatar
        archor={anchorRef}
        label={uname}
        size={size}
        handleClick={() => {
          honeycomb.sendUiInteractionSpan(`clicked-user-avatar`);
          setOpen(!open);
        }}
        color
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        style={mainPanelStyle}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {pulldownMenu}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {mimicUserDlg && (
        <MimicUserDlg handleCancel={handleMimicUserCancel} currentUser={user} />
      )}
    </>
  );
};

export default PopMenu;

PopMenu.defaultProps = {
  width: 300,
  height: 400,
  callback: null,
  hasClosed: null,
  size: "medium",
  ldClient: null,
};

PopMenu.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  honeycomb: PropTypes.shape({ sendUiInteractionSpan: PropTypes.func })
    .isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  callback: PropTypes.func,
  hasClosed: PropTypes.func,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email_address: PropTypes.string.isRequired,
    keycloak: PropTypes.bool,
    contact_id: PropTypes.string,
  }).isRequired,
  ldClientMaster: PropTypes.shape().isRequired,
  ldClient: PropTypes.shape(),
  setDup: PropTypes.func.isRequired,
  size: PropTypes.string,
};
